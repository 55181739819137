import {createBrowserRouter, Outlet, RouterProvider, ScrollRestoration, useLoaderData, useLocation, useNavigation} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import GlobalStyle from "./globalStyles";
import styled from "styled-components";
import NavBar from "./components/NavBar/NavBar";
import Home, {homeAction, homeLoader} from "./routes/Home/Home";
import Media, {mediaLoader} from "./routes/Media/Media";
import {useEffect, useRef} from "react";
import useScrollSpy from "react-use-scrollspy";
import Footer from "./components/Footer/Footer";
import Commission, {commissionLoader} from "./routes/Commission/Commission";
import Committee, {committeeLoader} from "./routes/Committee/Committee";
import Privacy, {privacyLoader} from "./routes/Privacy/Privacy";
import NoMatch from "./routes/NoMatch/NoMatch";
import {NewSpinner} from "./components/Spinner/NewSpinner";
import nProgress from 'nprogress';

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const Main = styled.main`
    //margin-top: 84px; // Fixed menu's height
    min-height: calc(100vh - 84px); // To make sure the footer is always outside the screen

    @media screen and (max-width: 1024px) {
        //margin-top: 60px;
        min-height: calc(100vh - 60px);
    }
`;

async function layoutLoader({request}) {
    const layoutData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/conditions`, { signal: request.signal})
        .then((res) => {
            if (!res.ok) {
                return {
                    status: res.status,
                };
            }
            return res.json();
        });

    return layoutData;
}

const Layout = () => {
    const data = useLoaderData();

    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -80,
    });

    const location = useLocation();
    const navigation = useNavigation();

    // useEffect(() => {
    //     if(location.hash) {
    //         document.querySelector(location.hash).scrollIntoView()
    //     }
    // }, [location])

    // NProgress bar (loadingbar on page change)
    nProgress.configure({
        showSpinner: false,
        template: '<div class="bar" role="bar"></div>',
        minimum: 0.3,
        speed: 400,
    });

    // Normal navigation in data router is idle -> loading -> idle, so start nProgress if state changed to "loading"
    if(navigation.state === "loading") {
        nProgress.start();
    }

    if(navigation.state === "idle") {
        nProgress.done();
    }

    // When the location changed, complete the nProgress
    useEffect(() => {
        nProgress.done();
    }, [location])

    return (
        <StyledLayout>
            <GlobalStyle />
            <ScrollRestoration />

            <NavBar activeSection={activeSection} />
            <Main>
                <Outlet context={{sectionRefs}} />
            </Main>
            <Footer data={data} />
        </StyledLayout>
    )
}

const StyledInitialLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--color-white);
`;

const InitialLayout = () => {
    return (
        <StyledInitialLayout>
            <GlobalStyle />
            <NavBar />
            <NewSpinner />
        </StyledInitialLayout>
    )
}

function App() {

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Layout />,
            loader: layoutLoader,
            errorElement: <InitialLayout />,
            shouldRevalidate: () => {return false;},
            children: [
                {
                    index: true,
                    element: <Home />,
                    loader: homeLoader,
                    action: homeAction,
                    errorElement: <NoMatch />,
                    shouldRevalidate: () => {return false;},
                },
                {
                    path: '/media/:slug',
                    element: <Media />,
                    loader: mediaLoader,
                    errorElement: <NoMatch />,
                    shouldRevalidate: () => {return false;},
                },
                {
                    path: '/commissies',
                    element: <Commission />,
                    loader: commissionLoader,
                    errorElement: <NoMatch />,
                    shouldRevalidate: () => {return false;},
                },
                {
                    path: '/comite-van-aanbeveling',
                    element: <Committee />,
                    loader: committeeLoader,
                    errorElement: <NoMatch />,
                    shouldRevalidate: () => {return false;},
                },
                {
                    path: '/privacy-policy',
                    element: <Privacy />,
                    loader: committeeLoader,
                    errorElement: <NoMatch />,
                    shouldRevalidate: () => {return false;},
                },
                {
                    path: '*',
                    element: <NoMatch />
                }
            ]
        }
    ]);

  return (
    <HelmetProvider>
        <RouterProvider router={router} fallbackElement={<InitialLayout />} />
    </HelmetProvider>
  );
}

export default App;
