import styled from 'styled-components';
import Header from '../../components/Header/Header';
import {Container} from '../../components/Container/Container';
import {Body, Headline3} from '../../components/Text/Text';
import {SquareImageInner, SquareImageOuter} from '../../components/Image/Image';
import {Placeholder} from '../../components/Placeholder/Placeholder';
import {useLoaderData} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

const Grid = styled.div`
    padding-top: 80px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    row-gap: 60px;
    
    @media screen and (max-width: 1024px) {
        gap: 20px;
        row-gap: 30px;
        padding-top: 40px;
        padding-bottom: 60px;
    }
    
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media screen and (max-width: 475px) {
        gap: 15px;
        row-gap: 25px;
        padding-top: 18px;
        padding-bottom: 40px;
    }
    
    @media screen and (max-width: 375px) {
        grid-template-columns: 1fr;
    }
`;

const StyledHeadline3 = styled(Headline3)`
    margin-bottom: 5px;
`;

export async function committeeLoader({request}) {
    const committeeData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/committee`, { signal: request.signal})
        .then((res) => {
            if (!res.ok) {
                return {
                    status: res.status,
                };
            }
            return res.json();
        });

    return committeeData;
}

function Committee() {
    const data = useLoaderData();

    return (
        <>
            <Helmet>
                <title>{data?.title || "Comité van aanbeveling"} &mdash; Jurist & Werk Congres</title>
            </Helmet>

            <Header title={data?.title || "Comité van aanbeveling"} />

            <Container>
                <Grid>
                    {data?.items?.map(item => (
                        <article key={item.id}>
                            {item.image ? (
                                <SquareImageOuter>
                                    <SquareImageInner src={item.image} alt={item.title}/>
                                </SquareImageOuter>
                            ) : (
                                <SquareImageOuter>
                                    <Placeholder />
                                </SquareImageOuter>
                            )}

                            {item.title && <StyledHeadline3>{item.title}</StyledHeadline3>}
                            {item.text && <Body $small>{item.text}</Body>}
                        </article>
                    ))}
                </Grid>
            </Container>
        </>
    );
}

export default Committee;
