import styled from 'styled-components';
import Header from '../../components/Header/Header';
import {Container} from '../../components/Container/Container';
import {Body, Headline2} from '../../components/Text/Text';
import {SquareImageInner, SquareImageOuter} from '../../components/Image/Image';
import {Placeholder} from '../../components/Placeholder/Placeholder';
import {useLoaderData} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

const CurrentCommission = styled.div`
    padding: 60px 0;
    background-color: var(--color-light);

    @media screen and (max-width: 1024px) {
        padding: 40px 0;
    }

    @media screen and (max-width: 475px) {
        padding: 30px 0;
    }
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;

    @media screen and (max-width: 1024px) {
        gap: 20px;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const ImageBox = styled.div`
    flex: 0 0 400px;
    
    @media screen and (max-width: 1024px) {
        flex: 0 1 300px;
    }

    @media screen and (max-width: 600px) {
        flex: unset;
    }
`;

const TextBox = styled.div`
    flex: 2;
`;

const Grid = styled.div`
    padding-top: 60px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    row-gap: 50px;

    @media screen and (max-width: 1024px) {
        padding-top: 30px;
        padding-bottom: 60px;
        gap: 20px;
        row-gap: 30px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media screen and (max-width: 475px) {
        padding-bottom: 40px;
        grid-template-columns: 1fr;
        gap: 15px;
        row-gap: 25px;
    }
`;

const StyledHeadline2 = styled(Headline2)`
    margin-bottom: ${props => props.$currentCommission ? '20px' : '10px'};
`;

const StyledBody = styled(Body)`
    white-space: pre-line;
`;

export async function commissionLoader({request}) {
    const commissionData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/commission`, { signal: request.signal})
        .then((res) => {
            if (!res.ok) {
                return {
                    status: res.status,
                };
            }
            return res.json();
        });

    return commissionData;
}

function Commission() {
    const data = useLoaderData();
    const currentCommission = data?.items[0];

    return (
        <>
            <Helmet>
                <title>{data?.title || "Commissies"} &mdash; Jurist & Werk Congres</title>
            </Helmet>

            <Header title={data?.title || "Commissies"} description={data?.text || "Bekijk de huidige commissie en de commissies van de afgelopen jaren."} />

            <CurrentCommission>
                <Container>
                    {currentCommission?.title && <StyledHeadline2 $currentCommission $noTransform>{currentCommission.title}</StyledHeadline2>}
                    <Flex>
                        <ImageBox>
                            {currentCommission?.image ? (
                                <SquareImageOuter $noMargin>
                                    <SquareImageInner src={currentCommission.image} alt={currentCommission.title} />
                                </SquareImageOuter>
                            ) : (
                                <SquareImageOuter $noMargin>
                                    <Placeholder />
                                </SquareImageOuter>
                            )}
                        </ImageBox>
                        <TextBox>
                            <StyledBody $small>{currentCommission.text}</StyledBody>
                        </TextBox>
                    </Flex>
                </Container>
            </CurrentCommission>

            <Container>
                <Grid>
                    {data?.items?.slice(1).map(item => (
                        <div key={item.id}>
                            {item.title && <StyledHeadline2>{item.title}</StyledHeadline2>}
                            {item.image ? (
                                <SquareImageOuter>
                                    <SquareImageInner src={item.image} alt={item.title} />
                                </SquareImageOuter>
                            ) : (
                                <SquareImageOuter>
                                    <Placeholder />
                                </SquareImageOuter>
                            )}
                            {item.text && <StyledBody $small>{item.text}</StyledBody>}
                        </div>
                    ))}
                </Grid>
            </Container>
        </>
    );
}
export default Commission;
