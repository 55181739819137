import {Container} from '../Container/Container';
import styled from 'styled-components';
import {Body} from '../Text/Text';
import {Link, useLocation} from 'react-router-dom';
import DotIcon from '../../assets/icons/dot.svg';

const StyledFooter = styled.footer`
    padding-bottom: 30px;
    ${props => props.$onLight && 'background-color: var(--color-light)'};
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    border-top: 1px solid var(--color-light-400);
    gap: 30px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const Links = styled.div``;

const FooterText = styled(Body)`
    font-size: var(--fs-footer);
`;

const FooterLink = styled(FooterText).attrs({
    as: Link
})`
    @media screen and (min-width: 526px) {
        &:not(:last-of-type) {
            margin: 0 4px 0 0;

            &:after {
                content: '';
                margin-left: 4px;
                display: inline-flex;
                background-image: url(${DotIcon});
                background-size: 18px 18px;
                height: 18px;
                width: 18px;
                position: relative;
                top: 4px;
            }
        }   
    }

    &:hover {
        color: var(--color-green);
    }
    
    @media screen and (max-width: 525px) {
        display: block;
    }
`;

const Footer = ({data}) => {
    const {pathname} = useLocation();

    return (
        <StyledFooter $onLight={pathname === '/'}>
            <Container>
                <Flex>
                    <FooterText>Auteursrecht &copy; {new Date().getFullYear()} Jurist & Werk. Alle rechten voorbehouden.</FooterText>
                    <Links>
                        {data['terms-and-conditions'] && <FooterLink to={data['terms-and-conditions']} target="_blank" rel="noreferrer">Algemene voorwaarden</FooterLink>}
                        {data?.avg && <FooterLink to="privacy-policy" target="_blank" rel="noreferrer">Privacyverklaring</FooterLink>}
                    </Links>
                </Flex>
            </Container>
        </StyledFooter>
    )
}

export default Footer;