import styled from 'styled-components';
import {Container} from '../Container/Container';
import {Body, Headline1} from '../Text/Text';

const StyledHeader = styled.header`
    background-color: var(--color-green);
    padding-top: var(--menu-height);
`;

const StyledHeadline1 = styled(Headline1)`
    margin-bottom: 15px;
    max-width: 600px;
`;

const StyledBody = styled(Body)`
    max-width: 600px;
`;

const Inner = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 300px;
    padding: 20px 0;

    @media screen and (max-width: 1024px) {
        min-height: 250px;
    }

    @media screen and (max-width: 475px) {
        min-height: 220px;
    }
`;

const Header = ({title, description}) => {
    return (
        <StyledHeader>
            <Container>
                <Inner>
                    {title && <StyledHeadline1>{title}</StyledHeadline1>}
                    {description && <StyledBody $white>{description}</StyledBody>}
                </Inner>
            </Container>
        </StyledHeader>
    );
}

export default Header;