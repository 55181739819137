import {Container} from '../Container/Container';
import styled from 'styled-components';
import {Body, Headline2} from '../Text/Text';
import {Link} from 'react-router-dom';

const StyledOffices = styled.section`
    padding: 50px 0 0;

    @media screen and (max-width: 1024px) {
        padding: 40px 0 0;
    }
`;

const StyledHeadline2 = styled(Headline2)`
    text-align: center;
    line-height: 1.5;
    margin-bottom: 30px;
    
    @media screen and (max-width: 1024px) {
        text-align: left;
        margin-bottom: 18px;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;
    overflow-x: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar { display: none; }
    
    @media screen and (max-width: 1024px) {
        width: calc(100% + 36px);
        margin-left: -18px;
        padding: 0 20px;
    }
`;

const Office = styled(Link)`
    background-color: var(--color-light);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
    min-width: 158px;
    text-decoration: none;
    padding: 4px;

    &:hover {
        background-color: var(--color-light-600);
    }
    
    @media screen and (max-width: 525px) {
        height: 100px;
    }
`;

const Logo = styled.img`
    display: block;
    width: 100%;
    max-width: 130px;
    height: auto;
    max-height: 80px;
    object-fit: contain;
`;

const OfficeName = styled(Body)`
    text-align: center;
`;

const Offices = ({data, hash, sectionRef}) => {
    if(data.status) return null;

    return (
        <StyledOffices id={hash} ref={sectionRef}>
            <Container>
                <StyledHeadline2>{data?.title || "Aanwezige kantoren"}</StyledHeadline2>
                <Grid>
                    {data?.items?.map((item, index) => (
                        <Office key={index} to={item?.link} target="_blank" rel="noreferrer">
                            {item?.image
                                ? <Logo src={item?.image} alt={item?.title} title={item?.title}/>
                                : <OfficeName $small>{item?.title}</OfficeName>
                            }
                        </Office>
                    ))}
                </Grid>
            </Container>
        </StyledOffices>
    )
}

export default Offices;