import styled from "styled-components";

export const Body = styled.p`
    font-weight: ${props => props.$bold ? 'var(--fw-bold)' 
        : props.$semiBold ? 'var(--fw-semi-bold)'
        : 'var(--fw-regular)'
    };
    font-size: ${props => props.$small ? 'var(--fs-body-small)' : 'var(--fs-body)'};
    color: ${props => props.$white ? 'var(--color-white)' : 'var(--color-body)'}; 
    line-height: 1.5;
    margin: 0;

    @media screen and (max-width: 475px) {
        overflow-wrap: anywhere;
    }
`;

export const Headline1 = styled.h1`
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-heading-1);
    color: var(--color-white);
    line-height: 1.25;
    margin: 0;
    
    @media screen and (max-width: 475px) {
        overflow-wrap: anywhere;
    }
`;

export const Headline2 = styled(Headline1).attrs({
    as: 'h2'
})`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-2);
    color: var(--color-headline);
    line-height: 1;
    ${props => !props.$noTransform && 'text-transform: uppercase;' };
`;

export const Headline3 = styled(Headline1).attrs({
    as: 'h3'
})`
    font-size: var(--fs-heading-3);
    color: var(--color-headline);
`;