import styled from 'styled-components';
import {ReactComponent as PlaceholderImage} from '../../assets/img/placeholder.svg';

export const Placeholder = styled(PlaceholderImage)`
    display: block;
    width: 100%;
    height: 100%;
    
    @supports not (aspect-ratio: 1 / 1) {
        position: absolute;
        top: 0;
    }
`;