import {Container} from '../Container/Container';
import styled from 'styled-components';
import {Body, Headline2} from '../Text/Text';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import {Form, Link, useActionData} from 'react-router-dom';
import {Button} from '../Button/Button';

const StyledContact = styled.section`
    padding: 100px 0 60px;
    background-color: var(--color-light);

    @media screen and (max-width: 768px) {
        padding: 40px 0;
    }
`;

const StyledHeadline2 = styled(Headline2)`
    margin-bottom: 18px;
`;

const StyledBody = styled(Body)`
    margin-bottom: 8px;
`;

const StyledLink = styled(Link)`
    margin-top: 18px;
    display: inline-block;
    svg { path { fill: var(--color-headline); } }

    &:not(:last-of-type) {
        margin-right: 8px;
    }
    
    &:hover {
        svg { path { fill: var(--color-green); } }
    }
`;

const Flex = styled.div`
    display: flex;
    gap: 40px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const ContentBox = styled.article`
    flex: 1;
`;

const FormBox = styled.div`
    flex: 1;
    
    ${Button} {
        display: block;
        margin-left: auto;
    }
`;

const Input = styled.input`
    font-family: var(--font-poppins);
    font-size: var(--fs-form);
    line-height: 1.55;
    padding: 12px 18px;
    width: 100%;
    border: 1px solid ${props => props?.$error ? 'var(--color-error)' : 'var(--color-label-stroke)'};
    outline: none;
    border-radius: 8px;
    max-height: 50px;

    &::placeholder {
        color: var(--color-light-400);
    }

    &:focus {
        border: 1px solid var(--color-green);
    }
`;

const TextArea = styled(Input).attrs({
    as: 'textarea'
})`
    resize: vertical;
    max-height: unset;
    min-height: 51px;
`;

const Label = styled(Body).attrs({
    as: 'label'
})`
    font-weight: var(--fw-medium);
    font-size: var(--fs-form);
`;

const Required = styled.span`
    color: var(--color-green);
`;

const FormRow = styled.div`
    margin-bottom: 10px;
    
    &:last-of-type {
        margin-bottom: 30px;
    }
`;

const Error = styled(Body).attrs({
    $small: true,
})`
    color: var(--color-error);
    margin-top: 5px;
    font-size: var(--fs-error);
`;

const Contact = ({hash, sectionRef}) => {
    const actionData = useActionData();

    return (
        <StyledContact id={hash} ref={sectionRef}>
            <Container>
                <Flex>
                    <ContentBox>
                        <StyledHeadline2>Neem contact op</StyledHeadline2>
                        <StyledBody $small>Vul het contactformulier in en wij zullen zo spoedig mogelijk contact met u opnemen. Blijf op de hoogte van het congres door ons te volgen op social media.</StyledBody>
                        <StyledLink to="https://www.facebook.com/jurist.enwerk/" target="_blank" rel="noreferrer"><FacebookIcon /></StyledLink>
                        <StyledLink to="https://www.instagram.com/juristenwerk/" target="_blank" rel="noreferrer"><InstagramIcon /></StyledLink>
                    </ContentBox>
                    <FormBox>
                        {actionData?.success ? (
                            <Body $small>Het formulier is succesvol verzonden.</Body>
                        ) : (
                            <Form method="post">
                                <FormRow>
                                    <Label htmlFor="name">Naam<Required aria-label="required">*</Required></Label>
                                    <Input id="name" type="text" name="name" placeholder="Naam" $error={!!actionData?.errors?.name} />
                                    {actionData?.errors?.name && <Error>{actionData?.errors?.name}</Error>}
                                </FormRow>

                                <FormRow>
                                    <Label htmlfFor="email">E-mail adres<Required aria-label="required">*</Required></Label>
                                    <Input id="email" type="text" name="email" placeholder="E-mail adres" $error={!!actionData?.errors?.email} />
                                    {actionData?.errors?.email && <Error>{actionData?.errors?.email}</Error>}
                                </FormRow>

                                <FormRow>
                                    <Label htmlFor="subject">Onderwerp</Label>
                                    <Input id="subject" type="subject" name="subject" placeholder="Onderwerp" $error={!!actionData?.errors?.subject} />
                                    {actionData?.errors?.subject && <Error>{actionData?.errors?.subject}</Error>}
                                </FormRow>

                                <FormRow>
                                    <Label htmlFor="message">Bericht</Label>
                                    <TextArea id="message" type="message" name="message" placeholder="Bericht" rows="5" $error={!!actionData?.errors?.message} />
                                    {actionData?.errors?.message && <Error>{actionData?.errors?.message}</Error>}
                                </FormRow>

                                <Button as="button" type="submit">Verzenden</Button>
                            </Form>
                        )}
                    </FormBox>
                </Flex>
            </Container>
        </StyledContact>
    )
}

export default Contact;