import styled from 'styled-components';
import Logo from '../../assets/img/logo-big.png';
import {Button} from '../Button/Button';
import {Container} from '../Container/Container';

const StyledHeaderBanner = styled.header`
    background-color: var(--color-light);
    ${props => props.$backgroundImage && `background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${props.$backgroundImage});`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 636px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: var(--menu-height);
    
    @media screen and (max-width: 768px) {
        height: 500px;
    }

    @media screen and (max-width: 475px) {
        height: 389px;
    }
`;

const StyledLogo = styled.img`
    display: block;
    max-width: 888px;
    width: 100%;
    max-height: 143px;
    height: auto;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    margin-top: 30px;
    
    @media screen and (max-width: 475px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const HeaderBanner = ({data, sectionRef}) => {
    if(data?.status) return null;

    return (
        <StyledHeaderBanner $backgroundImage={data?.image} ref={sectionRef}>
            <Container>
                <StyledLogo src={Logo} alt="Jurist & Werk Congres" />
                <Buttons>
                    {data?.link1 && <Button to={data?.link1}>{data?.link1Label}</Button>}
                    {data?.link2 && <Button to={data?.link2} $green>{data?.link2Label}</Button>}
                </Buttons>
            </Container>
        </StyledHeaderBanner>
    );
}

export default HeaderBanner;