import {useLoaderData, useOutletContext} from 'react-router-dom';
import HeaderBanner from '../../components/HeaderBanner/HeaderBanner';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import Congress from '../../components/Congress/Congress';
import Offices from '../../components/Offices/Offices';
import Media from '../../components/Media/Media';
import Contact from '../../components/Contact/Contact';
import {Helmet} from 'react-helmet-async';

export async function homeLoader({request}) {

    const [homeData, officeData, photoAlbumData] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_BASE_URL}/home`, { signal: request.signal})
            .then((res) => {
                if (!res.ok) {
                    return {
                        status: res.status,
                    };
                }
                return res.json();
            }),

        fetch(`${process.env.REACT_APP_API_BASE_URL}/office`, { signal: request.signal})
            .then((res) => {
                if (!res.ok) {
                    return {
                        status: res.status,
                    };
                }
                return res.json();
            }),

        fetch(`${process.env.REACT_APP_API_BASE_URL}/photoalbum`, { signal: request.signal})
            .then((res) => {
                if (!res.ok) {
                    return {
                        status: res.status,
                    };
                }
                return res.json();
            })
    ])

    return ({homeData, officeData, photoAlbumData});
}

export async function homeAction({ request }) {
    const formData = await request.formData();

    const name = formData.get("name");
    const email = formData.get("email");
    const subject = formData.get("subject");
    const message = formData.get("message");

    const errors = {};

    // Validate name and email client sided
    if (typeof name !== "string" || !name.length > 0) {
        errors.name = "Een naam is verplicht";
    }

    if (typeof email !== "string" || !email.includes("@") || !email.length > 0) {
        errors.email = "Vul een geldig e-mailadres in";
    }

    // Return data if we have errors
    if (Object.keys(errors).length) {
        return {"errors": errors};
    }

    // Otherwise post the contactObj and return with feedback
    const contactObj = {
        "name": name,
        "email": email,
        "subject": subject,
        "message": message,
    }

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/contact`, {
        method: "POST",
        body: JSON.stringify(contactObj),
        headers: {
            'Content-Type': 'application/json',
        },
        signal: request.signal
    })
        .then((response) => {
            if(!response.ok) {
                return response.json().then((errorResponse) => {
                    throw errorResponse;
                });
            }

            return response.json();
        })
        .then((result) => {
            return {"success": true};
        })
        .catch((error) => {
            let formattedError = {};

            // Format the error object the same as client side validation
            error.error.forEach((err) => {
                formattedError[err.fieldName] = err.message;
            });

            return {
                "errors": formattedError
            };
        })
}

function Home() {
    const {homeData, officeData, photoAlbumData} = useLoaderData();
    const {sectionRefs} = useOutletContext();

    return (
        <>
            <Helmet>
                <title>Jurist & Werk Congres</title>
            </Helmet>

            <HeaderBanner data={homeData.headerBlock} sectionRef={sectionRefs[0]} />

            <InfoBlock data={homeData.infoBlock.items[0]} hash="over-ons" sectionRef={sectionRefs[1]} />

            <Congress data={homeData.congress} hash="congres" sectionRef={sectionRefs[2]} />

            <Offices data={officeData} hash="aanwezige-kantoren" sectionRef={sectionRefs[3]} />

            <InfoBlock data={homeData.infoBlock.items[1]} hash="inschrijven" sectionRef={sectionRefs[4]} />

            <Media data={photoAlbumData} hash="media" sectionRef={sectionRefs[5]} />

            <Contact hash="contact" sectionRef={sectionRefs[6]} />
        </>
    );
}
export default Home;
