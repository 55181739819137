import {createGlobalStyle} from "styled-components";
import normalize from "styled-normalize";

const GlobalStyle = createGlobalStyle`
    ${normalize}
    
    * { 
        box-sizing: border-box;
    }
    
    :root {
        /* Constants */
        --menu-height: 84px;
        
        /* Color properties */
        --color-green: #006622;
        --color-green-400: #217A3F;
        --color-green-600: #00541C;
        --color-orange: #E87240;
        --color-orange-600: #D26334;
        
        --color-headline: #212225;
        --color-body: #3B3D41;
        
        --color-white: #fff;
        --color-black: #000;
        
        --color-light: #F9F9F9;
        --color-light-400: #D9D9D9;
        --color-light-600: #F1F1F1;
        --color-background: #FEFEFE;
        --color-label-stroke: #F4F4F4;
        --color-error: #FF6666;
        
        /* Font properties */
        --font-poppins: 'Poppins', sans-serif;

        --fw-regular: 400;
        --fw-medium: 500;
        --fw-semi-bold: 600;
        --fw-bold: 700;
        
        --fs-navigation: 1.6rem;
        --fs-navigation-sub: 1.6rem;
        --fs-button: 1.8rem;
        --fs-heading-1: 4.8rem;
        --fs-heading-2: 3.6rem;
        --fs-heading-3: 2.2rem;
        --fs-body: 2rem;
        --fs-body-small: 1.8rem;
        --fs-tab: 2rem;
        --fs-form: 1.6rem;
        --fs-footer: 1.6rem;
        --fs-error: 1.4rem;
        
        @media screen and (max-width: 1024px) {
            --menu-height: 60px;
            --fs-navigation: 2rem;
            --fs-button: 1.6rem;
            --fs-heading-1: 3.6rem;
            --fs-heading-2: 2.6rem;
            --fs-heading-3: 2rem;
            --fs-body: 1.6rem;
            --fs-body-small: 1.6rem;
            --fs-tab: 1.8rem;
        }
    }

    html, body {
        margin: 0;
        padding: 0;
        font-family: var(--font-poppins);
        font-size: 62.5%;
        background-color: var(--color-background);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
        scroll-padding-top: 70px;
        
        @media screen and (max-width: 475px) {
            scroll-padding-top: 40px;
        }
    }
    
    /* NProgress navigation loader bar */
    #nprogress {
        pointer-events: none;
    }

    #nprogress .bar {
        background: var(--color-orange);
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
    }
`

export default GlobalStyle;