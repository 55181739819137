import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Button = styled(Link)`
    background-color: ${props => props.$green ? 'var(--color-green)' : 'var(--color-orange)'};
    color: var(--color-white);
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    border-radius: 3px;
    padding: 8px 24px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    line-height: 1.5;
    border: none;
    cursor: pointer;
    width: fit-content;
    
    &:hover {
        background-color: ${props => props.$green ? 'var(--color-green-600)' : 'var(--color-orange-600)'};
    }
`;

export const SquareButton = styled(Button)`
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;