import styled from 'styled-components';
import Header from '../../components/Header/Header';
// import {Container} from '../../components/Container/Container';
import {Body, Headline3} from '../../components/Text/Text';
import {SquareImageInner, SquareImageOuter} from '../../components/Image/Image';
import {Placeholder} from '../../components/Placeholder/Placeholder';
import {useLoaderData} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

const Grid = styled.div`
    padding-top: 80px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    row-gap: 60px;
    
    @media screen and (max-width: 1024px) {
        gap: 20px;
        row-gap: 30px;
        padding-top: 40px;
        padding-bottom: 60px;
    }
    
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media screen and (max-width: 475px) {
        gap: 15px;
        row-gap: 25px;
        padding-top: 18px;
        padding-bottom: 40px;
    }
    
    @media screen and (max-width: 375px) {
        grid-template-columns: 1fr;
    }
`;

const StyledHeadline3 = styled(Headline3)`
    margin-bottom: 5px;
`;

export const Container = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 50px 18px;
`;

export async function privacyLoader({request}) {
    const committeeData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/committee`, { signal: request.signal})
        .then((res) => {
            if (!res.ok) {
                return {
                    status: res.status,
                };
            }
            return res.json();
        });

    return committeeData;
}

function Committee() {
    const data = useLoaderData();

    return (
        <>
            <Helmet>
                <title>{data?.title || "Privacy policy"} &mdash; Jurist & Werk Congres</title>
            </Helmet>

            <Header title={data?.title || "Privacy policy"} />

            <Container>
                <article>
                    <Body>
                        <h2>1. Inleiding</h2>
                        <p>Stichting Jurist en Werk hecht veel waarde aan de bescherming van uw persoonsgegevens. In deze Privacy policy willen we heldere en transparante informatie geven over hoe wij omgaan met persoonsgegevens.</p>

                        <h2>2. Over Stichting Jurist en Werk</h2>
                        <p>De Stichting Jurist en werk is gevestigd aan Steenschuur 25, 2311 ES Leiden, ingeschreven in het handelsregister in de Kamer van Koophandel onder nummer 41167517. Stichting Jurist en Werk is ten aanzien van de verwerking van uw persoonsgegevens door de Jurist en Werk commissie de verwerkingsverantwoordelijke.</p>

                        <h2>3. Hoe gebruikt Stichting Jurist en Werk jouw persoonsgegevens?</h2>
                        <p>Wij doen er alles aan om uw privacy te waarborgen en gaan daarom zorgvuldig om met persoonsgegevens. Stichting Jurist en Werk houdt zich in alle gevallen aan de toepasselijke wet- en regelgeving, waaronder de Algemene Verordening Gegevensbescherming. Dit brengt met zich mee dat wij in ieder geval:</p>
                            <ul>
                                <li>Uw persoonsgegevens verwerken in overeenstemming met het doel waarvoor deze zijn verstrekt, deze doelen en type persoonsgegevens zijn beschreven in deze Privacy policy;</li>
                                <li>De verwerking van uw persoonsgegevens beperken tot enkel die gegevens welke minimaal nodig zijn voor de doeleinden waarvoor ze worden verwerkt;</li>
                                <li>Vragen om uw uitdrukkelijke toestemming als wij deze nodig hebben voor de verwerking van uw persoonsgegevens;</li>
                                <li>Passende technische en organisatorische maatregelen hebben genomen zodat de beveiliging van uw persoonsgegevens gewaarborgd is;</li>
                                <li>Geen persoonsgegevens doorgeven aan andere partijen, tenzij dit nodig is voor uitvoering van de doeleinden waarvoor ze zijn verstrekt;</li>
                                <li>Op de hoogte zijn van uw rechten omtrent uw persoonsgegevens, u hierop willen wijzen en deze rechten respecteren.</li>
                            </ul>
                        <p>Stichting Jurist en Werk is verantwoordelijk voor de verwerking van uw persoonsgegevens. Indien u na het doornemen van deze Privacy policy, of in algemenere zin, vragen heeft of contact met ons wenst op te nemen kan dit via de contactgegevens onder aan dit document.</p>



                        <h2>4. Verwerking van persoonsgegevens potentiële deelnemers aan het Congres</h2>
                        <p>Persoonsgegevens van potentiële deelnemers worden door Stichting Jurist en Werk verwerkt ten behoeve van de volgende doelstellingen:</p>
                        <ul>
                            <li>a) Het organiseren van het Jurist & Werk Congres;</li>
                            <li>b) De algehele administratie;</li>
                            <li>c) Eventuele selectie voor deelname als student aan het Jurist & Werk Congres;</li>
                            <li>d) Eventuele selectie van studenten door deelnemende kantoren;</li>
                            <li>e) Informatieverstrekking in de vorm van nieuwsbrieven en/of gerichte communicatie; en</li>
                            <li>f) Het voorkomen van en/of adequaat reageren op (medische) ongelukken.</li>
                        </ul>
                        <p>Voor de bovenstaande doelstellingen kan Stichting Jurist en Werk de volgende persoonsgegevens
                            van u vragen:

                            <ul>
                                <li>Voornaam;</li>
                                <li>Achternaam;</li>
                                <li>Geslacht;</li>
                                <li>Leeftijd of geboortedatum;</li>
                                <li>Adresgegevens;</li>
                                <li>Telefoonnummer;</li>
                                <li>E-mailadres;</li>
                                <li>Woonplaats;</li>
                                <li>Verbonden universiteit;</li>
                                <li>Studierichting(en);</li>
                                <li>Studiejaar;</li>
                                <li>Studiepunten;</li>
                                <li>Geplande afstudeerdatum;</li>
                                <li>Curriculum vitae;</li>
                                <li>Gewaarmerkte cijferlijst;</li>
                                <li>Optionele motivatiebrief;</li>
                                <li>Pasfoto;</li>
                                <li>Dieetwensen/allergieën.</li>
                            </ul>

                            <p>Uw persoonsgegevens worden door Stichting Jurist en Werk opgeslagen ten behoeve van
                                bovengenoemde doelstellingen voor de volgende termijnen:</p>

                            <ul>
                                <li>a) Zo lang als noodzakelijk voor dit doeleinde;</li>
                                <li>b) Ten minste zeven jaar;</li>
                                <li>c) Zo lang als noodzakelijk voor dit doeleinde;</li>
                                <li>d) Zo lang als noodzakelijk voor dit doeleinde;</li>
                                <li>e) Ten minste zeven jaar; en</li>
                                <li>f) Zo lang als noodzakelijk voor dit doeleinde. Versie d.d. 21 maart 2022 5.</li>
                            </ul>


                            <h2>Verstrekking aan derden</h2>
                            <p>De gegevens die u aan ons geeft kunnen wij aan derde partijen verstrekken indien dit
                                noodzakelijk is voor uitvoering van de hierboven beschreven doeleinden.</p>

                            <p>Zo maken wij gebruik van verschillende derde partijen voor de volgende handelingen:</p>
                            <ul>
                                <li>Het verzorgen van de online inschrijvingen;</li>
                                <li>Het leggen van contact tussen kantoren en de deelnemers waarin zij na afloop van het congres geïnteresseerd zijn;</li>
                                <li>Het gebruik van een applicatie om tijdens en na het congres in het uitwisselen van contactgegevens tussen deelnemers en kantoren te bemiddelen.</li>
                            </ul>
                                <p>Wanneer persoonsgegevens door worden gegeven aan derde partijen wordt gebruik
                                gemaakt van een verwerkersovereenkomst. Met deze partijen (verwerkers) maken wij
                                hierin uiteraard de nodige afspraken om de beveiliging van uw persoonsgegevens te
                                waarborgen. Verder zullen wij de door uw verstrekte gegevens niet aan andere
                                partijen verstrekken, tenzij dit wettelijk verplicht en toegestaan is. Een voorbeeld
                                hiervan is dat de politie in het kader van een onderzoek (persoons)gegevens bij ons
                                opvraagt. In een dergelijk geval dienen wij medewerking te verlenen en zijn wij dan
                                ook verplicht deze gegevens af te geven. Tevens kunnen wij persoonsgegevens delen
                                    met derden indien u ons hier schriftelijk toestemming voor geeft.</p>


                            <h2>6. Binnen de EU</h2>
                                <p>Wij verstrekken geen persoonsgegevens aan partijen welke gevestigd zijn buiten de EU. </p>


                            <h2>7. Minderjarigen</h2>
                            <p>Wij verwerken enkel en alleen persoonsgegevens van
                                minderjarigen (personen jongen dan 16 jaar) indien daarvoor schriftelijke
                                toestemming is gegeven door de ouder, verzorger of wettelijke vertegenwoordiger.</p>

                            <h2>8. Bewaartermijn</h2>
                            <p>Stichting Jurist en Werk bewaart persoonsgegevens niet langer dan
                                noodzakelijk voor het doel waarvoor deze zijn verstrekt dan wel op grond van de wet
                                is vereist.</p>
                            <h2>9. Beveiliging</h2>
                            <p>Wij trachten passende technische en organisatorische
                                maatregelen te nemen om persoonsgegevens van u te beschermen tegen onrechtmatige
                                verwerking, hieraan kan in dit kader worden gedacht:</p>
                            <ul>
                                <li>Alle personen die namens Stichting Jurist en Werk van uw gegevens kennis kunnen nemen, zijn gehouden aan geheimhouding daarvan;</li>
                                <li>Gebruik maken van een gebruikersnaam en een wachtwoordbeleid op al onze systemen;</li>
                                <li>Het pseudonimiseren en zorgen voor de encryptie van persoonsgegevens als daar aanleiding toe is;</li>
                                <li>Het maken back-ups van de persoonsgegevens om deze te kunnen herstellen bij fysieke of technische incidenten;</li>
                                <li>Het testen en evalueren regelmatig onze maatregelen;</li>
                                    <li>Het informeren van medewerkers over het belang van de bescherming van persoonsgegevens.</li>
                            </ul>


                            <h2>10. Rechten omtrent uw gegevens</h2>
<p>U heeft recht op inzage, rectificatie, beperking van
                                verwerking of verwijdering van persoonsgegevens welke wij van u ontvangen hebben.
                                Tevens kunt u bezwaar maken tegen de verwerking van uw persoonsgegevens (of een deel
                                hiervan) door ons of door één van onze verwerkers. Ook heeft u het recht om de door
                                u verstrekte gegevens door ons te laten overdragen aan uzelf of in opdracht van u
                                direct aan een andere partij. Wij kunnen u vragen om u te legitimeren voordat wij
                                gehoor kunnen geven aan voornoemde verzoeken.</p>
                            <p>Mogen wij uw persoonsgegevens
                                verwerken op basis van een door u gegeven toestemming hiertoe, dan heeft u altijd
                                het recht deze toestemming in te trekken.</p>


                            <h2>11. Klachten</h2>
                            <p>Mocht u een klacht hebben
                                over de verwerking van uw persoonsgegevens dan vragen wij u hierover direct contact
                                met ons op te nemen. Komen wij er samen met u niet uit dan vinden wij dit natuurlijk
                                erg vervelend. U heeft altijd het recht een klacht in te dienen bij de <a href="https://www.autoriteitpersoonsgegevens.nl/" target="_blank">Autoriteit Persoonsgegevens</a>, dit is de toezichthoudende autoriteit op het gebied van
                                privacybescherming.</p>


                            <h2>12. Vragen</h2>
                            <p>Als u naar aanleiding van ons Privacy Statement nog
                                vragen of opmerkingen heeft, neem dan contact met ons op.</p>

                            <h2>Contactgegevens</h2>
                            <p>Stichting Jurist en Werk<br />Steenschuur 25<br />2311 ES Leiden<br />Email: <a href="mailto:commissie@juristenwerk.nl" target="_blank">commissie@juristenwerk.nl</a></p>
                        </p>

                    </Body>
                </article>
            </Container>
        </>
);
}

export default Committee;
