import styled from 'styled-components';
import Header from '../../components/Header/Header';
import FsLightbox from "fslightbox-react";
import {useState} from 'react';
import {Container} from '../../components/Container/Container';
import {HorizontalImageInner, HorizontalImageOuter} from '../../components/Image/Image';
import {redirect, useLoaderData, useParams} from 'react-router-dom';
import {Body} from '../../components/Text/Text';
import {Helmet} from 'react-helmet-async';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {NewSpinner} from '../../components/Spinner/NewSpinner';

const EmptyNotice = styled(Body)`
    padding-top: 80px;
    padding-bottom: 100px;

    @media screen and (max-width: 1024px) {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    @media screen and (max-width: 475px) {
        padding-top: 18px;
        padding-bottom: 40px;
    }
`;

const Grid = styled.div`
    padding-top: 80px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 18px;

    @media screen and (max-width: 1024px) {
        gap: 10px;
        padding-top: 40px;
        padding-bottom: 60px;
    }
    
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(6, 1fr);
    }
    
    @media screen and (max-width: 475px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
        padding-top: 18px;
        padding-bottom: 40px;
    }
`;

const Item = styled.div`
    cursor: pointer;
    overflow: hidden;
    
    &:nth-of-type(9n-8), &:nth-of-type(9n-7), &:nth-of-type(9n-6) {
        grid-column: span 4;
    }

    &:nth-of-type(9n-5), &:nth-of-type(9n-4) {
        grid-column: span 6;
    }

    &:nth-of-type(9n-3), &:nth-of-type(9n-2), &:nth-of-type(9n-1), &:nth-of-type(9n) {
        grid-column: span 3;
    }
    
    @media screen and (max-width: 768px) {
        &:nth-of-type(5n-4), &:nth-of-type(5n-3), &:nth-of-type(5n-2) {
            grid-column: span 2;
        }

        &:nth-of-type(5n-1), &:nth-of-type(5n) {
            grid-column: span 3;
        }
    }

    @media screen and (max-width: 475px) {
        &:nth-of-type(3n-2), &:nth-of-type(3n-1) {
            grid-column: unset;
        }
        
        &:nth-of-type(3n) {
            grid-column: span 2;
        }
    }
`;

const StyledHorizontalImageInner = styled(HorizontalImageInner)`
    transition: transform .2s ease;

    ${Item}:hover & {
        transform: scale(1.1);
    }
`;

const SpinnerWrapper = styled.div`
    text-align: center;
    margin: 0 0 100px 0;
`;

export async function mediaLoader({request, params}) {

    const [mediaData, photosData] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_BASE_URL}/photoalbum/${params.slug}`, { signal: request.signal})
            .then((res) => {
                if (!res.ok) {
                    switch (res.status) {
                        case 400: {
                            return redirect('/404');
                        }
                        default: {
                            return {
                                status: res.status,
                            };
                        }
                    }
                }
                return res.json();
            }),

        fetch(`${process.env.REACT_APP_API_BASE_URL}/photoalbum/${params.slug}/photos?page[size]=9&page[number]=1`, { signal: request.signal})
            .then((res) => {
                if (!res.ok) {
                    return {
                        status: res.status,
                    };
                }
                return res.json();
            })
    ])


    return ({mediaData, photosData});
}

function Media() {
    const {mediaData, photosData} = useLoaderData();
    let { slug } = useParams();

    let count = photosData.count;

    const [dataWithImage, setDataWithImage] = useState(photosData?.items?.filter(obj => obj.image !== null));
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [error, setError] = useState();
    const [page, setPage] = useState(2);

    async function loadMore() {
        if(count === dataWithImage.length) {
            return setHasNextPage(false);
        }

        setLoading(true);

        try {
            const fetchedPhotos = await fetch(`${process.env.REACT_APP_API_BASE_URL}/photoalbum/${slug}/photos?page[size]=9&page[number]=${page}`)
                .then((res) => {
                    if (!res.ok) {
                        return {
                            status: res.status,
                        };
                    }
                    return res.json();
                })

            let photosWithImage = fetchedPhotos?.items?.filter(obj => obj.image !== null)

            setDataWithImage([...dataWithImage, ...photosWithImage])
            setPage(page + 1);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    const [sentryRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: '0px 0px 0px 0px',
    });

    // Lightbox
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1,
    });

    const openLightbox = (index) => {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index + 1,
        });
    };

    return (
        <>
            <Helmet>
                <title>{mediaData?.title} &mdash; Jurist & Werk Congres</title>
            </Helmet>

            <Header title={mediaData?.title} description={mediaData?.text} />

            <Container>
                {dataWithImage?.length === 0 ? (
                    <EmptyNotice>Het lijkt erop dat dit fotoalbum nog geen foto's bevat. Kom op een later moment terug.</EmptyNotice>
                ) : (
                    <>
                        <Grid>
                            {dataWithImage?.map((item, index) => (
                                <Item key={item.id} onClick={() => openLightbox(index)}>
                                    <HorizontalImageOuter>
                                        <StyledHorizontalImageInner src={item.image} alt={"image"} />
                                    </HorizontalImageOuter>
                                </Item>
                            ))}
                        </Grid>
                        {(loading || hasNextPage) && (
                            <SpinnerWrapper ref={sentryRef}>
                                <NewSpinner />
                            </SpinnerWrapper>
                        )}
                    </>
                )}
            </Container>

            <FsLightbox
                toggler={lightboxController.toggler}
                sources={dataWithImage?.map((item) => item.image)}
                slide={lightboxController.slide}
                type="image"
                key={dataWithImage?.length}
            />
        </>
    );
}
export default Media;
