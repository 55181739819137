import Header from '../../components/Header/Header';
import {Container} from '../../components/Container/Container';
import {Body} from '../../components/Text/Text';
import {Button} from '../../components/Button/Button';
import styled from 'styled-components';
import {Helmet} from 'react-helmet-async';

const StyledContainer = styled(Container)`
    padding-top: 80px;
    padding-bottom: 100px;

    @media screen and (max-width: 1024px) {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    @media screen and (max-width: 475px) {
        padding-top: 18px;
        padding-bottom: 40px;
    }
`;

const Buttons = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    margin-top: 30px;
`;

function NoMatch() {
    return (
        <>
            <Helmet>
                <title>404 Pagina niet gevonden &mdash; Jurist & Werk Congres</title>
            </Helmet>

            <Header title="404 Pagina niet gevonden" description="Deze pagina kon niet gevonden worden." />

            <StyledContainer>
                <Body>Het lijkt erop dat deze pagina niet bestaat. U bent wellicht opzoek naar:</Body>
                <Buttons>
                    <Button to='/'>Home</Button>
                    <Button to='/commissies'>Commissies</Button>
                    <Button to='/comite-van-aanbeveling'>Comité van aanbeveling</Button>
                </Buttons>
            </StyledContainer>
        </>
    );
}

export default NoMatch;