import styled from 'styled-components';
import {Container} from '../Container/Container';
import {Body, Headline2} from '../Text/Text';
import AppStoreBadge from '../../assets/img/appstore.jpg';
import PlayStoreBadge from '../../assets/img/playstore.jpg';
import {Button} from '../Button/Button';
import {Link} from 'react-router-dom';

const StyledInfoBlock = styled.section`
    ${props => props.$type === 'over-ons' && 'background-color: var(--color-light);'};
    padding: ${props => props.$type === 'over-ons' ? '100px 0' : '150px 0 100px'};

    @media screen and (max-width: 768px) {
        padding: ${props => props.$type === 'over-ons' ? '40px 0 0' : '40px 0'};
    }
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${props => props.$type === 'inschrijven' && 'flex-direction: row-reverse;'};
    gap: 40px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;

const ImageBox = styled.div`
    flex-shrink: 2;
    flex-basis: 640px;

    @media screen and (max-width: 768px) {
        flex-basis: unset;
    }
`;

const Image = styled.img`
    display: block;
    width: 100%;
    height: auto;
    ${props => props.$type === 'over-ons' && 'border-radius: 4px;'};
    
    @media screen and (max-width: 768px) {
        ${props => props.$type === 'over-ons' && `
            width: calc(100% + 36px);
            margin-left: -18px;
            border-radius: 0;
        `};
    }
`;

const ContentBox = styled.div`
    flex-basis: 500px;
    flex-grow: 0;
    
    ${Headline2} {
        margin-bottom: 15px;
    }

    @media screen and (max-width: 768px) {
        flex-basis: unset;
    }
`;

const Buttons = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    margin-top: 30px;
    
    @media screen and (max-width: 475px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const Badges = styled.div`
    display: flex;
    gap: 18px;
    margin-top: 30px;
    flex-wrap: wrap;
`;

const Badge = styled.img`
    display: block;
    width: 100%;
    max-width: ${props => props.$appStore ? '155px' : '175px'};
`;

const StyledBody = styled(Body)`
    white-space: pre-wrap;
`;

const InfoBlock = ({data, hash, sectionRef}) => {
    if(data?.status) return null;

    return (
        <StyledInfoBlock id={hash} ref={sectionRef} $type={data?.typeBlock}>
            <Container>
                <Flex $type={data?.typeBlock}>
                    <ImageBox>
                        <Image src={data?.image} alt={data?.title} $type={data?.typeBlock} />
                    </ImageBox>
                    <ContentBox>
                        <Headline2>{data?.title}</Headline2>
                        <StyledBody>{data?.text}</StyledBody>
                        {data?.typeBlock === 'over-ons' &&
                            <Buttons>
                                {data?.link1 && <Button to={data?.link1}>{data?.link1Label}</Button>}
                                {data?.link2 && <Button to={data?.link2}>{data?.link2Label}</Button>}
                            </Buttons>
                        }

                        {data?.typeBlock === 'inschrijven' &&
                            <Badges>
                                {data?.link1 &&
                                    <Link to={data?.link1}>
                                        <Badge src={AppStoreBadge} alt="Download on the App Store" $appStore />
                                    </Link>
                                }
                                {data?.link2 &&
                                    <Link to={data?.link2}>
                                        <Badge src={PlayStoreBadge} alt="Get it on Google Play" />
                                    </Link>
                                }
                            </Badges>
                        }
                    </ContentBox>
                </Flex>
            </Container>
        </StyledInfoBlock>
    );
}

export default InfoBlock;