import styled from 'styled-components';

// Horizontal image (16:9)
export const HorizontalImageOuter = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: var(--color-background);
    
    @supports not (aspect-ratio: 16 / 9) {
        padding-top: 56.25%; /* 9/16*100 */
    }
`;

export const HorizontalImageInner = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        top: 0;
    }
`;

// Square image (1:1)
export const SquareImageOuter = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: var(--color-background);
    
    @supports not (aspect-ratio: 1 / 1) {
        padding-top: 100%; /* 1/1*100 */
    }

    ${props => !props.$noMargin && `
        margin-bottom: 25px;
        
        @media screen and (max-width: 1024px) {
            margin-bottom: 15px;
        }
    `};
`;

export const SquareImageInner = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @supports not (aspect-ratio: 1 / 1) {
        position: absolute;
        top: 0;
    }
`;