import styled from 'styled-components';
import {Container} from '../Container/Container';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {Body, Headline2, Headline3} from '../Text/Text';
import {useState} from 'react';
import SelectIcon from '../../assets/icons/select.svg';
import ActiveSelectIcon from '../../assets/icons/select-active.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {SquareImageInner, SquareImageOuter} from '../Image/Image';

const StyledCongress = styled.section`
    padding: 100px 0;

    @media screen and (max-width: 768px) {
        padding: 40px 0 0;
    }
`;

const StyledHeadline2 = styled(Headline2)`
    text-align: center;
    line-height: 1.5;
    
    @media screen and (max-width: 525px) {
        text-align: left;
    }
`;

const StyledHeadline3 = styled(Headline3)`
    margin-bottom: 8px;
`;

const CongressBody = styled(Body)`
    white-space: pre-line;
`;

const StyledBody = styled(Body)`
    text-align: center;
    margin-bottom: 30px;
    
    @media screen and (max-width: 768px) {
        margin-bottom: 18px;
    }
    
    @media screen and (max-width: 525px) {
        text-align: left;
    }
`;

const StyledTabList = styled(TabList)`
    display: ${props => props.$isMobile ? 'none' : 'flex'};
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar { display: none; }

    @media screen and (max-width: 768px) {
        width: calc(100% + 36px);
        margin-left: -18px;
        padding: 0 20px;
    }
`;

const StyledTab = styled(Tab)`
    flex: 1 0 146px;
    padding: 16px 0;
    border-bottom: 1px solid var(--color-light-400);
    text-align: center;
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-tab);
    color: var(--color-headline);
    text-transform: uppercase;
    cursor: pointer;
    
    &.active {
        border-bottom: 4px solid var(--color-green);
        color: var(--color-green);
    }
    
    &:focus {
        outline: none;
    }
    
    &:hover {
        color: var(--color-green);
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.$columns}, 1fr);
    gap: 30px;
    padding-top: 18px;
    
    ${props => props.$columns !== 1 && `
        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 525px) {
            grid-template-columns: 1fr;
        }
    `}
    
    ${SquareImageInner} {
        border-radius: 4px;
    }
`;

const StyledSelect = styled.select`
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-tab);
    color: var(--color-headline);
    text-transform: uppercase;
    line-height: 1.5;
    margin: 0;
    padding: 8px 12px 8px 24px;
    width: 100%;
    border: 1px solid var(--color-light-400);
    outline: none;
    border-radius: 3px;
    appearance: none;
    background-color: var(--color-background);
    background-image: url(${SelectIcon});
    background-repeat: no-repeat;
    background-position: right 12px center, 0 0;

    &:focus {
        border: 1px solid var(--color-green);
        background-image: url(${ActiveSelectIcon});
    }
`;

const StyledOption = styled.option`
    text-transform: lowercase;
`;

const Congress = ({data, hash, sectionRef}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState('0');

    const {width} = useWindowDimensions();
    let isMobile = width <= 525;

    if(data.status) return null;

    return (
        <StyledCongress id={hash} ref={sectionRef}>
            <Container>
                <StyledHeadline2>{data?.title}</StyledHeadline2>
                <StyledBody>{data?.date}</StyledBody>
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} selectedTabClassName="active">
                    {isMobile &&
                        <StyledSelect value={selectedOption} onChange={e => {setSelectedOption(e.target.value); setTabIndex(parseInt(e.target.value))}}>
                            {data?.congressTab?.map((item, index) => (
                                <StyledOption key={index} value={index}>{item?.title}</StyledOption>
                            ))}
                        </StyledSelect>
                    }

                    <StyledTabList $isMobile={isMobile}>
                        {data?.congressTab?.map((item, index) => (
                            <StyledTab key={index}>{item?.title}</StyledTab>
                        ))}
                    </StyledTabList>

                    {data?.congressTab?.map((item, index) => (
                        <TabPanel key={index}>
                            <Grid $columns={item?.items?.length <= 3 ? item?.items?.length : 1}>
                                {item?.items?.map((item, index) => (
                                    <article key={index}>
                                        {item?.image &&
                                            <SquareImageOuter>
                                                <SquareImageInner src={item?.image} alt={item?.title} />
                                            </SquareImageOuter>
                                        }
                                        {item?.title && <StyledHeadline3>{item?.title}</StyledHeadline3> }
                                        {item?.text && <CongressBody>{item?.text}</CongressBody> }
                                    </article>
                                ))}
                            </Grid>
                        </TabPanel>
                    ))}
                </Tabs>
            </Container>
        </StyledCongress>
    );
}

export default Congress;