import styled from 'styled-components';
import {Body, Headline2, Headline3} from '../Text/Text';
import {Container} from '../Container/Container';
import {SquareButton} from '../Button/Button';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-right.svg';
import {Link} from 'react-router-dom';
import {HorizontalImageInner, HorizontalImageOuter} from '../Image/Image';

const StyledMedia = styled.section`
    padding: 50px 0 100px;

    @media screen and (max-width: 1024px) {
        padding: 40px 0;
    }
`;

const StyledHeadline2 = styled(Headline2)`
    text-align: center;
    line-height: 1.5;
    margin-bottom: 30px;
    
    @media screen and (max-width: 1024px) {
        margin-bottom: 18px;
    }
`;

const StyledHeadline3 = styled(Headline3)`
    margin-bottom: 4px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 18px;
    grid-row-gap: 40px;

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 525px) {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
    }
`;

const PhotoAlbum = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    color: inherit;
`;

const StyledHorizontalImageOuter = styled(HorizontalImageOuter)`
    overflow: hidden;
    position: relative;
    margin-bottom: 18px;
    border-radius: 4px;
`;

const StyledHorizontalImageInner = styled(HorizontalImageInner)`
    transition: transform .2s ease;

    ${PhotoAlbum}:hover & {
        transform: scale(1.1);
    }
`;

const StyledSquareButton = styled(SquareButton)`
    position: absolute;
    right: 8px;
    bottom: 8px;
`;

const Media = ({data, hash, sectionRef}) => {
    if(data.status) return null;

    return (
        <StyledMedia id={hash} ref={sectionRef}>
            <Container>
                <StyledHeadline2>{data?.title || "Media"}</StyledHeadline2>
                <Grid>
                    {data?.items?.map((item, index) => (
                        <PhotoAlbum key={index} to={`/media/${item?.slug}`}>
                            <StyledHorizontalImageOuter>
                                <StyledHorizontalImageInner src={item.image} alt={item.title} />
                                    <StyledSquareButton as="button"><ArrowIcon /></StyledSquareButton>
                            </StyledHorizontalImageOuter>

                            <StyledHeadline3>{item?.title}</StyledHeadline3>
                            <Body>{item?.text}</Body>
                        </PhotoAlbum>
                    ))}
                </Grid>
            </Container>
        </StyledMedia>
    )
}

export default Media;